export const FINMS_URL = `${window.location.protocol}//account.${window.location.host.replace(
  'finmsapp.', ''
)}`;

export const APP_NAME= 'finms'

export const API_URL_WITHOUT_SERVER_API = `${window.location.protocol}//${window.location.host}`;

export const API_URL =
  process.env.REACT_APP_API_URL  || // Если переменная задана в .env, используем её
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://finmsapp.development.crypto.extract-sweet.ru/server/api' // Если переменная true
    : `${window.location.protocol}//${window.location.host}/server/api`);

export const MAP_URL =
  process.env.REACT_APP_MAP_URL ??
  `${
    window.location.protocol
  }//exchangemap.${window.location.host.replace('finmsapp.', '')}`;

export const MAP_API_URL =
  process.env.REACT_APP_MAP_URL  ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://exchangemap.development.crypto.extract-sweet.ru/server/partner/api'
    : `${
    window.location.protocol
  }//exchangemap.${window.location.host.replace('finmsapp.', '')}/server/partner/api`);


export const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://id.development.crypto.extract-sweet.ru'
    : `${window.location.protocol}//id.${window.location.host}`.replace(
    'finmsapp.',
    ''
  ));

export const ACCOUNT_URL_API =
  process.env.REACT_APP_ACCOUNT_URL ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://account.development.crypto.extract-sweet.ru'
    : `${window.location.protocol}//account.${window.location.host}`.replace(
    'finmsapp.',
    ''
  ));

export const ACCOUNT_URL =
  process.env.REACT_APP_ACCOUNT_URL ??
  `${window.location.protocol}//account.${window.location.host}`.replace(
    'finmsapp.',
    ''
  );

export const EDENEX_ACCOUNT_URL = `${window.location.protocol}//${window.location.host.replace('finmsapp.', 'account.')}/server`

export const UNLEASH_URL =
  process.env.REACT_APP_UNLEASH_URL ??
  `https://unleash.${window.location.host}/proxy`.replace(
    'finmsapp.',
    ''
  );

export const REACT_APP_UNLEASH_PROXY_SECRETS =
  process.env.REACT_APP_UNLEASH_PROXY_SECRETS || '';
export const REACT_APP_ENVIRONMENT_NAME =
  process.env.REACT_APP_ENVIRONMENT_NAME || 'testing';

export const WS_URL = process.env.REACT_APP_WS_URL || (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true' ? 'wss://wss.development.crypto.extract-sweet.ru/centrifugo/connection/websocket' :   `wss://wss.${window.location.host}/centrifugo/connection/websocket`.replace(
    'finmsapp.',
    ''));

export const KEYCLOAK_REALM = 'master';
export const KEYCLOAK_CLIENT_ID = 'crypto.web';

export const ACCOUNT_DATA_PROVIDER = 'account';
export const CONFIG_DATA_PROVIDER = 'config';

export const EXCHANGE_DATA_PROVIDER = 'exchange';
export const ETH_COIN_DATA_PROVIDER = 'eth-coin';
export const BTC_COIN_DATA_PROVIDER = 'btc-coin';

export const SPOT_DATA_PROVIDER = 'spot';
export const P2P_DATA_PROVIDER = 'p2p';

export const BTC_USDT_SPOT_DATA_PROVIDER = 'btcusdt-spot';
export const DEFAULT_PAIR = 'ETH/BTC';

export const DEFAULT_PAIR_WITH_DASH = 'ETH-BTC';

export const PHYSICAL_EXCHANGER_DATA_PROVIDER = 'physical-exchanger';
